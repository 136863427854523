import * as faceLandmarksDetection from "@tensorflow-models/face-landmarks-detection";
import "@tensorflow/tfjs-backend-webgl";
import React, { useRef, useState, useEffect } from "react";
import Webcam from "react-webcam";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import styles from "./SelfTest3.module.css";

const SelfTest3 = () => {
  const webcamRef = useRef(null);
  const navigate = useNavigate();
  
  const [model, setModel] = useState(null);
  const [faceDetected, setFaceDetected] = useState(false);
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");

  useEffect(() => {
    const loadModel = async () => {
      const detectorModel = faceLandmarksDetection.SupportedModels.MediaPipeFaceMesh;
      const detectorConfig = {
        runtime: "mediapipe",
        solutionPath: "https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh",
      };
      const detector = await faceLandmarksDetection.createDetector(detectorModel, detectorConfig);
      setModel(detector);
    };

    loadModel();
  }, []);

  const detectFace = async () => {
    if (webcamRef.current && model) {
      const video = webcamRef.current.video;
      const predictions = await model.estimateFaces( video );

      if (predictions.length > 0) {
        setFaceDetected(true);
        console.log("face Detected")
        return true;
      } else {
        setFaceDetected(false);
        console.log("face NOT Detected")
        return false;
      }
    } else return false;
  };

  const capturePhotoAndMeasure = async () => {
    console.log("capturing...");
    if (!name || !age || !gender || !height || !weight) {
      alert("Please fill in all the required fields before capturing the photo.");
      return; // Exit the function if validation fails
    }
  
    // Await the result of detectFace()
    const face = await detectFace();
    console.log("face value boolean--------->", face);
  
    if (!face) {
      alert("Please ensure your face is detected before capturing the photo.");
      return;
    }
  
    const imageSrc = webcamRef.current.getScreenshot();
  
    if (imageSrc) {
      try {
        const response = await axios.post("https://test.aivot.ai/measureModel", {
          height,
          weight,
          age,
          gender,
        });
  
        if (response.data) {
          const result = response.data;
          const dataObj = {
            name,
            age,
            gender,
            height,
            weight,
            neck: result.Neck,
            shoulder: result.Shoulder,
            waist: result.Waist,
            chest: result.Chest,
            hip: result.Hip,
            thigh: result.Thigh,
            knee: result.Knee,
            ankle: result.Ankle,
            inseam: result.Inseam,
            outseam: result.Outseam,
            arm_length: result.Arm,
            foot: result.Foot,
            picture: imageSrc,
          };
  
          await postUserData(dataObj);
  
          navigate("/result", {
            state: {
              name,
              age,
              gender,
              height,
              weight,
              result,
            },
          });
        }
      } catch (error) {
        console.error("Error capturing photo or calling API:", error);
      }
    } else {
      console.error("Photo capture failed");
    }
  };
  

  const postUserData = async (dataObj) => {
    try {
      const response = await axios.post(
        "https://test.aivot.ai/auth/userResult",
        dataObj
      );
      if (response.status === 201) {
        console.log("User data successfully posted:", response.data);
      } else {
        console.error("Error posting user data:", response.status);
      }
    } catch (error) {
      console.error("Error posting user data:", error);
    }
  };

  // useEffect(() => {
  //   const interval = setInterval(detectFace, 100); // Check face detection every 100ms
  //   return () => clearInterval(interval); // Clean up the interval when the component unmounts
  // }, [model]);

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.formSection}>
          <div className={styles.heading}>Take your body measurement in a few seconds.</div>
          <br />
          <div className={styles.formGroup}>
            <div className={styles.label}>Name</div>
            <input
              type="text"
              placeholder="Name should not contain any special character, number"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className={styles.formGroup}>
            <div className={styles.label}>Age</div>
            <input
              type="number"
              placeholder="Age should not contain any special character or alphabets"
              value={age}
              onChange={(e) => setAge(e.target.value)}
            />
          </div>
          <div className={styles.formGroup}>
            <div className={styles.label}>Gender</div>
            <select
              value={gender}
              onChange={(e) => setGender(e.target.value)}
            >
              <option value="" disabled selected hidden>Select gender</option>
              <option>Male</option>
              <option>Female</option>
            </select>
          </div>
          <div className={styles.formGroup}>
            <div className={styles.label}>Height</div>
            <input
              type="number"
              placeholder="Height should be entered in meters"
              value={height}
              onChange={(e) => setHeight(e.target.value)}
            />
          </div>
          <div className={styles.formGroup}>
            <div className={styles.label}>Weight</div>
            <input
              type="number"
              placeholder="Weight should be entered in kilograms"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
            />
          </div>
        </div>

        <div className={styles.captureSection}>
          <div className={styles.heading1}>Capture photo</div>
          <div className={styles.captureContainer}>
            <div className={styles.cameraFrame}>
              <Webcam
                audio={false}
                mirrored={true}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                className={styles.cameraFeed}
              />
            </div>
            <button
              className={styles.captureButton}
              onClick={capturePhotoAndMeasure}
              // disabled={!faceDetected} // Disable capture if face is not detected
            >
              Capture
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelfTest3;
